import frFR from './i18n/fr-fr.common.json'

const locales = [
  {
    code: 'fr-fr',
    name: 'France',
  },
]

export default {
  defaultLocale: locales[0].code,
  locales,
  messages: { fr: frFR },
  parsePages: false,
  detectBrowserLanguage: {
    useCookie: false,
  },
}
